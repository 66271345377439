import React from "react";
import { graphql } from "gatsby";
import { GatsbySeo, BreadcrumbJsonLd } from "gatsby-plugin-next-seo";
import Layout from "~/components/layout";
import PageHeading from "~/components/styled/page-heading";
import Image from "~/components/image";
import TourList from "~/components/tour-list";
import { Link } from "gatsby";
import stars from "~/images/stars-4.5.svg";
import tustbadge from "~/images/trust-badge.png";

const ActivityPage = ({ data }) => {
	console.log(data);
	const tours = data.allStrapiTour.edges;
	const flatTours = tours.map(({ node }) => node);

	const regions = data.allStrapiRegion.edges;
	const flatRegions = regions.map(({ node }) => node);
	// const seo = {
	//   title: data.strapiActivity.name,
	// }

	return (
		<Layout>
			{/* <SEO seo={seo} /> */}
			<GatsbySeo
				title={`All ${data.strapiActivity.name} tours`}
				description={`Join us on our ${data.strapiActivity.name} and have an adventure of a lifetime.`}
				// languageAlternates={[
				// 	{
				// 		hrefLang: "en-US",
				// 		href: `https://www.artofbicycletrips.com/activities/${data.strapiActivity.slug}`,
				// 	},
				// 	{
				// 		hrefLang: "en",
				// 		href: `https://www.artofbicycletrips.com/activities/${data.strapiActivity.slug}`,
				// 	},
				// 	{
				// 		hrefLang: "en-IN",
				// 		href: `https://www.artofbicycletrips.in/activities/${data.strapiActivity.slug}`,
				// 	},
				// 	{
				// 		hrefLang: "x-default",
				// 		href: `https://www.artofbicycletrips.com/activities/${data.strapiActivity.slug}`,
				// 	},
				// ]}
			/>
			<BreadcrumbJsonLd
				itemListElements={[
					{
						position: 1,
						name: "Activities",
						item: "https://artofbicycletrips.com/activities",
					},
					{
						position: 2,
						name: `${data.strapiActivity.name}`,
					},
				]}
			/>

			{/* like Hero */}
			<section className="relative bg-white border-b-0 border-gray-900 overflow-hidden">
				<div className="flex flex-col lg:flex-row items-center">
					{/* Left Half */}
					{/* <div className="lg:w-1/2 py-8 px-8 lg:px-0 lg:pr-20 text-center lg:text-left">
						<div className="flex flex-col items-center lg:items-start">
							<h1 className="text-2xl text-black font-normal sm:text-4xl md:text-4xl lg:text-4xl xl:text-4xl">
								<span className="font-light   ">Premier</span>{" "}
								<span className="text-primary ">
									{" "}
									{data.strapiActivity.name} Adventures
								</span>
							</h1>
							<p className="mt-3 text-lg sm:text-xl mb-10 text-gray-600 ">
								We're all about small and local details.
							</p>
						</div>
					</div> */}

					{/* Left Half */}
					<div className="lg:w-1/2 py-8 px-8 lg:px-0 lg:pr-20 text-center lg:text-left">
						<div className="flex flex-col items-center lg:items-start">
							<h1 className="text-2xl text-black font-normal sm:text-4xl md:text-4xl lg:text-4xl xl:text-4xl">
								{/* <span className="font-light   ">Premier</span>{" "} */}
								<span className="text-black font-bold">
									{" "}
									{data.strapiActivity.name} Adventures
								</span>
							</h1>
							<p className="mt-3 text-base sm:text-lg mb-10 text-gray-600 ">
								Boutique and personalized {data.strapiActivity.name} adventures for
								every journey.
							</p>
							{/* {data.strapiRegion.ebike == true && (
								<div className="mb-10 ">
									<CheckIcon className="h-6 w-6 bg-primary2 text-white rounded-full p-1 inline-flex mr-2" />
									<span className="text-base text-primary2 font-medium ">
										E-bikes Available
									</span>
								</div>
							)} */}
							<div className="flex flex-col w-full md:w-auto md:flex-row  md:space-x-4">
								<a
									href="#explore"
									className="bg-primary mb-4 md:mb-0 capitalize tracking-wide font-medium text-white px-4 py-3 md:py-4 md:px-8 rounded-full"
								>
									Explore trips
								</a>
								<Link
									to="/design-trip/"
									className="bg-white capitalize border font-medium tracking-wide  border-primary text-primary px-4 py-3 md:py-4 md:px-6 rounded-full"
								>
									Design private trip
								</Link>
							</div>
						</div>
					</div>

					{/* Right Half */}
					<div className="lg:w-1/2 mt-2 lg:mt-0">
						<Image
							className="rounded-lg shadow-md transform border border-gray-200"
							image={data.strapiActivity.image}
							alt={data.strapiActivity.image.name.split("-").join(" ").split(".")[0]}
							loading="lazy"
						/>
					</div>
				</div>
			</section>

			{/* <section className="relative lg:pb-28 xl:pb-28 bg-white border-b-0 border-gray-900 overflow-hidden">
				<div className="max-w-7xl mx-auto">
					<div className="relative pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
						<div className="mt-10 mx-auto lg:mx-0 max-w-xl sm:mt-12 md:mt-16 lg:mt-20 xl:mt-28">
							<div className="sm:text-center  lg:text-left">
								<h1 className=" text-gray-900 lg:text-3xl  xl:text-4xl">
									<span className="block  xl:inline font-normal">Premier</span>{" "}
									<span className="block text-primary  xl:inline font-normal">
										{data.strapiActivity.name} Adventures
									</span>{" "}
								</h1>

								<p className="mt-3 mb-10 leading-relaxed sm:mt-5 sm:max-w-md sm:mx-auto md:mt-5 lg:mx-0">
									We're all about small and local details.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="lg:absolute lg:pl-8 lg:inset-y-0 lg:right-0 lg:w-1/2 ">
					<div className="relative">
						<Image
							className="rounded-md shadow-md transform border border-gray-200"
							image={data.strapiActivity.image}
							alt={data.strapiActivity.image.name.split("-").join(" ").split(".")[0]}
							loading="lazy"
						/>
					</div>
				</div>
			</section> */}

			{/* {data.strapiActivity.image && (
				<div className="">
					<Image
						className="rounded-lg shadow-md transform"
						image={data.strapiActivity.image}
						alt={data.strapiActivity.image.name.split("-").join(" ").split(".")[0]}
						loading="lazy"
					/>
				</div>
			)} */}
			<section>
				<div className="mt-20">
					{/* trustpilot */}
					<div className="text-center my-10">
						<a
							href="https://www.trustpilot.com/review/artofbicycletrips.com"
							target="_blank"
							rel="noopener noreferrer"
							className="border border-gray-300 p-4 inline-block mx-auto"
						>
							<div className="text-center sm:flex sm:justify-center">
								<div className="flex flex-row justify-center items-center">
									<p className="text-base sm:text-lg font-semibold">Excellent</p>
									<img
										src={stars}
										alt="Trustpilot Logo"
										className="w-24 h-auto sm:w-28 sm:h-auto ml-4 inline-block"
									/>
								</div>
								<div className="flex flex-row mt-1 sm:mt-0 justify-center items-center">
									<p className="text-sm sm:text-sm font-normal sm:ml-3">4.6 out of 5</p>
									<img
										src={tustbadge}
										alt="Trustpilot Logo"
										className="w-auto h-6 sm:w-auto sm:h-6 ml-2 inline-block"
									/>
								</div>
							</div>
						</a>
					</div>
					<h2 className="text-center my-10">
						The Best {data.strapiActivity.name} Adventures
					</h2>
					{/* <div className="text-center">
					<PageHeading>{data.strapiActivity.name} Tours</PageHeading>
				</div> */}

					<p className="  mb-16 font-light mx-auto max-w-2xl">
						{data.strapiActivity.desc}
					</p>
				</div>

				{/* <div className="mt-20">
				
				{data.strapiActivity.desc && (
					<>
						{expanded ? (
							<div className="mb-20">
								<ReactMarkdown
									className="prose prose-sm sm:prose lg:prose-lg xl:prose-xl mb-6 mx-auto leading-loose font-light"
									children={data.strapiActivity.desc}
								/>
								<div className="text-center">
									<button className="text-primary inline-block" onClick={handleReadMore}>
										Read less <ChevronUpIcon className="h-4 w-4 inline-flex mr-2" />
									</button>
								</div>
							</div>
						) : (
							<div className="mb-20">
								<ReactMarkdown
									className="prose prose-sm sm:prose lg:prose-lg xl:prose-xl mb-6 mx-auto leading-loose font-light"
									children={`${data.strapiActivity.desc.substring(0, 600)}...`}
								/>
							
								<div className="text-center">
									<button className="text-primary inline-block" onClick={handleReadMore}>
										Read more <ChevronDownIcon className="h-4 w-4 inline-flex mr-2" />
									</button>
								</div>
							</div>
						)}
					</>
				)}
			</div> */}

				{/* Region List Start */}

				<div className="flex flex-row space-x-2 overflow-x-auto whitespace-nowrap capitalize py-8 mb-10">
					{flatRegions &&
						flatRegions.map((reg) => {
							return (
								<div key={reg.id}>
									<Link to={`/destinations/${reg.slug}`} key={reg.id}>
										<div className="">
											<span className="border border-primary p-4 md:p-6 rounded-lg">
												{reg.name}
											</span>
										</div>
									</Link>
								</div>
							);
						})}
				</div>
			</section>

			{/* Region List Start */}
			{/* <div className="flex flex-row space-x-2 overflow-x-auto whitespace-nowrap capitalize py-8">
				{data.strapiActivity.regions &&
					data.strapiActivity.regions.map((reg) => {
						return (
							<div key={reg.id}>
								<Link to={`/destinations/${reg.slug}`} key={reg.id}>
									<div className="">
										<span className="border border-primary p-4 md:p-6 rounded-lg">
											{reg.name}
										</span>
									</div>
								</Link>
							</div>
						);
					})}
			</div> */}
			{/* Region List End */}
			<section id="explore" className="mb-20">
				<div className="bg-landing py-20 px-4 md:px-8 lg:px-10 xl:px-12 rounded-2xl">
					<h2 className="mb-10">Explore Our {data.strapiActivity.name} Holidays</h2>

					{/* <AlltoursList tours={tours} /> */}
					<TourList tours={flatTours} />
				</div>
				{/* <div className="flex flex-col bg-primary rounded-md px-6 py-14 md:px-20 md:py-20 lg:flex-row lg:justify-between items-center my-6">
					<div className="max-w-xl text-center lg:text-left">
						<p className="md:text-xl lg:text-2xl text-white mb-6">
							Couldn't find a trip that matches your interest?
						</p>
						<p className=" text-white font-semibold uppercase md:text-xl lg:text-2xl">
							Design Your Own Adventure
						</p>
						<p className="text-xs font-light text-white md:text-sm">
							100% tailor-made based on your travel interests
						</p>
					</div>
					<div className="mt-10 lg:mt-0">
						<button className="border border-white px-8 py-3 md:py-4 md:px-8 rounded-full ">
							<Link to="/design-trip" className="text-white md:text-lg">
								Design trip
							</Link>
						</button>
					</div>
				</div> */}
			</section>
		</Layout>
	);
};

export const query = graphql`
	query ActivityQuery($slug: String!) {
		allStrapiTour(
			filter: { activity: { slug: { eq: $slug } } }
			sort: { fields: price, order: DESC }
		) {
			edges {
				node {
					id
					duration
					price
					slug
					title
					popular
					new
					featuredImage {
						localFile {
							publicURL
							childImageSharp {
								gatsbyImageData(
									layout: FULL_WIDTH
									placeholder: BLURRED
									aspectRatio: 1.3
								)
							}
						}
					}
					slider {
						id
						url
						name
						alternativeText
						localFile {
							childImageSharp {
								gatsbyImageData(aspectRatio: 1.3)
							}
						}
					}

					quickfacts {
						lodging
						level
					}
					activity {
						name
					}
					regions {
						name
						id
					}
					destination {
						name
					}
				}
			}
		}

		allStrapiRegion(sort: { fields: name, order: DESC }) {
			edges {
				node {
					id
					name
					slug
				}
			}
		}

		strapiActivity(slug: { eq: $slug }, locale: { eq: "en" }) {
			name
			desc
			slug
			regions {
				id
				name
				slug
			}
			image {
				name
				alternativeText
				localFile {
					childImageSharp {
						gatsbyImageData(aspectRatio: 1.2)
					}
				}
			}
		}
	}
`;

export default ActivityPage;
